
import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import { IconQQ, IconWechat, SuperLogin, IconWework } from '@tencent/super-login';
import { getOrigin } from 'components/util';
import {
  get_api_auth_login as getApiAuthLogin,
  post_api_auth_login_token_create as postApiAuthLoginTokenCreate,
} from 'components/api';
import { getQQConnectURL, getIoaUrl } from 'components/user';
import './login-panel.less';
import { Button, Checkbox, Dialog } from 'tdesign-react';

// NOTE 每次更新隐私协议 需要手动更新一下这个version
const PRIVACY_VERSION = '2022_07_22';
const LOCALSTORAGE_KEY = `isAgreement_${PRIVACY_VERSION}`;
export class LoginPanel extends Component {
  static validRedirectUrl(url) {
    return (/^(https?:\/\/)?(txc|support)\.(qq|oa)\.com($|\/|\\)/i).test(url);
  }

  static getIsAgreement() {
    try {
      return JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY));
    } catch {
      return false;
    }
  }

  constructor(props) {
    super(props);

    this.iframeRef = createRef();

    this.state = {
      token: '',
      type: 1,
      showExternalOALogin: '',
      isAgree: false,
    };
  }

  componentDidMount() {
    this.createToken();
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.willUnmount = true;
  }

  /**
     * 获取授权用的链接
     */
  get oauthUrl() {
    const { state } = this;
    // 绝对路径，便于手机扫码
    return `${getOrigin('dev_static')}/login/oauth?login_token=${state.token}`;
  }

  /**
     * 创建授权 token
     */
  createToken() {
    const onSuccess = (json) => {
      this.setState({
        token: json.data.token,
        showExternalOALogin: json.data.enable_rtx_login,
      });
      this.loopCheck();
    };

    postApiAuthLoginTokenCreate().then(onSuccess);
  }

  /**
     * 刷新授权 token
     */
  refreshToken() {
    this.setState({ type: LoginPanel.CODE.WAITING });
    this.createToken();
  }

  /**
     * 轮训是否授权
     */
  loopCheck() {
    const { state, props } = this;

    const delay = 500;

    console.log('检查');

    const onSuccess = (json) => {
      if (this.willUnmount) {
        return;
      }

      // 登录成功
      if (json.data && json.data.code === LoginPanel.CODE.SUCCESS) {
        this.setState({ type: LoginPanel.CODE.SUCCESS });
        this.onLoginSuccess();
        return;
      }

      // 二维码过期
      if (json.data && json.data.code === LoginPanel.CODE.EXPIRED) {
        this.setState({ type: LoginPanel.CODE.EXPIRED, token: '' });
        return;
      }

      // 扫码成功，等待用户确认
      if (json.data && json.data.code === LoginPanel.CODE.PENDING) {
        this.setState({ type: LoginPanel.CODE.PENDING });
      }

      this.timer = setTimeout(() => {
        this.loopCheck();
      }, delay);
    };

    getApiAuthLogin({ login_token: state.token }).then(onSuccess);
  }

  /**
     * 授权成功后跳转到目标页面
     */
  onLoginSuccess() {
    if (typeof this.props.onSuccess === 'function') {
      this.props.onSuccess();
      return;
    }

    window.location.href = this.props.redirectUrl;
  }

  /**
     * 同意协议
     */
  onAgreement() {
    this.createToken();
    localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(true));
  }

  /**
     * 加载协议成功
     */
  onLoadIframe() {
    if (!this.iframeRef.current) {
      return;
    }

    try {
      const { contentWindow } = this.iframeRef.current;

      // 隐藏 iframe 中的标题
      const title = contentWindow.document.getElementById('xy-title');
      title.style.display = 'none';
    } catch (error) {

    }
  }

  handleLoginClick = (e) => {
    if (!this.state.isAgree) {
      e.preventDefault();
      this.setShowIsAgreeDialog(true);
    }
  }

  setIsAgree = (isAgree) => {
    this.setState({ isAgree });
  }

  setShowIsAgreeDialog = (showIsAgreeDialog) => {
    this.setState({ showIsAgreeDialog });
  }

  render() {
    const { state, props } = this;
    const qqStyle = { display: props.showExternalQQLogin ? 'inline' : 'none' };
    const oaStyle = {
      marginLeft: 20,
      display: state.showExternalOALogin ? 'inline' : 'none',
    };
    const icon = state.showExternalOALogin ? [...props.icon, <IconWework key="wework" />] : props.icon;
    const footerText = state.showExternalOALogin ? 'QQ、微信、企业微信数据不互通' : props.footerText;

    return (
      <div className="login-panel">
        <SuperLogin
          icons={icon}
          platform={[]}
          type={state.type}
          qrcode={this.oauthUrl}
          onRetry={event => this.refreshToken()}
          agreementAccepted={state.isAgree}
          external={[
            <a
              className="super_login_qq_link"
              href={getQQConnectURL(props.redirectUrl)}
              onClick={this.handleLoginClick}
              key="qq"
              style={qqStyle}
            >
              QQ账号密码登录
            </a>,
            <a
              className="super_login_qq_link"
              href={getIoaUrl(props.redirectUrl)}
              onClick={this.handleLoginClick}
              key="ioa"
              style={oaStyle}
            >
              OA快捷登录
            </a>,
          ]}
          title={props.title}
          footerText={footerText}
        />
        <div className="login-panel__footer">

          <Checkbox checked={state.isAgree} onChange={this.setIsAgree}>
            <span>我已阅读并同意
              <a className="login-panel__footer-link" href="/xy/agreement.html" target="_blank"> 服务协议 </a>
              和
              <a className="login-panel__footer-link" href="/xy/privacy.html" target="_blank"> 隐私政策</a>
            </span>
          </Checkbox>
        </div>
        {
          state.showIsAgreeDialog
        && <Dialog
          placement="center"
          width={420}
          header="提示"
          className="dialog--theme-coral"
          visible
          onClose={() => {
            this.setShowIsAgreeDialog(false);
          }}
          footer={
            <Fragment>
              <Button
                theme="default"
                variant="outline"
                onClick={() => {
                  this.setShowIsAgreeDialog(false);
                }}
              >
                不同意
              </Button>
              <Button
                theme="primary"
                onClick={() => {
                  this.setIsAgree(true);
                  this.setShowIsAgreeDialog(false);
                }}
              >
                同意
              </Button>
            </Fragment>
          }
        >
          <p className="content">请先阅读并同意腾讯兔小巢用户服务协议和隐私协议</p>
        </Dialog>
        }
      </div>
    );
  }
}

LoginPanel.CODE = {
  EXPIRED: -1, // 二维码已过期
  WAITING: 1, // 等待用户扫码
  PENDING: 2, // 用户已扫码，等待确认
  SUCCESS: 3, // 用户授权登录
};

LoginPanel.propTypes = {
  /**
     * 标题文本
     */
  title: PropTypes.string,
  /**
     * 登录成功后的跳转 URL
     */
  redirectUrl: PropTypes.string,
  /**
     * 登录成功后的回调函数
     * @description 如果传入了此函数，则不会再处理 props.redirectUrl
     */
  onSuccess: PropTypes.func,
  showExternalQQLogin: PropTypes.bool,
  footerText: PropTypes.string,
  icon: PropTypes.array,
};

LoginPanel.defaultProps = {
  icon: [<IconQQ key="qq" />, <IconWechat key="wechat" />],
  footerText: 'QQ、微信数据不互通',
  showExternalQQLogin: true,
};
