/**
 * 重定向检查存在异步处理逻辑
 * 使用流式管理这些检查函数，提供中断操作阻止后续重定向逻辑执行
 */

class RedirectChecker {
  private exited = false;

  // 退出，不再执行后续
  exit() {
    this.exited = true;
  }

  batchRun(...dispatchs: (() => void)[]) {
    dispatchs.forEach((dispatch) => {
      this.run(dispatch);
    });
  }

  run(dispatch: () => void) {
    // 异步执行，避免报错中断同步代码
    setTimeout(() => {
      !this.exited && dispatch();
    }, 0);
  }
}

let redirectChecker: RedirectChecker;

export function getCurrentPipe() {
  if (!redirectChecker) {
    redirectChecker = new RedirectChecker();
  }

  return redirectChecker;
}

export function runPipe(...dispatchs: (() => void)[]) {
  return getCurrentPipe().batchRun(...dispatchs);
}

export function exitPipe() {
  getCurrentPipe().exit();
}
