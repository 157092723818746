/** 首页 - 帖子列表 */
export const PAGE_INDEX = 'scenes/embed__general/index';
/** 功能投票 */
export const PAGE_ROADMAP = 'scenes/embed__roadmap/index';
/** 常见问题 - 列表 */
export const PAGE_FAQ_LIST = 'scenes/embed__faqs-more/index';
/** 团队博客 - 列表 */
export const PAGE_BLOG_LIST = 'scenes/embed__blog-archive/index';
/** 团队博客 - 详情 */
export const PAGE_BLOG_DETAIL = 'scenes/embed__blog/index';
/** 团队博客 - 我们的故事 */
export const PAGE_BLOG_TEAM = 'scenes/embed__team/index';
/** 我的 */
export const PAGE_PROFILE = 'scenes/embed__profile/index';
/** 我的设置 */
export const PAGE_PROFILE_EDIT = 'scenes/embed__profile_edit/index';

/** 发帖页 */
export const PAGE_NEW_POST = 'scenes/embed__new-post/page/new-post/app';
/** 问题场景选择页 */
export const PAGE_PRO_SEC = 'scenes/embed__new-post/page/pro-sec/app';
/** 帖子详情 */
export const PAGE_POST_DETAIL = 'scenes/embed__post/index';
/** 话题详情 */
export const PAGE_TOPIC_DETAIL = 'scenes/embed__topic-detail/index';
/** 常见问题 - 详情 */
export const PAGE_FAQ_DETAIL = 'scenes/embed__faqs/index';
/** 常见问题分类 */
export const PAGE_FAQ_CATEGORY = 'scenes/embed__faqs-category/index';

/** 常见问题单个列表 */
export const PAGE_FAQ_SINGLE = 'scenes/embed__faqs-list/index';

/** 兼容旧版本首页 */
export const PAGE_INDEX_OLD = 'pages/index-v2/index-v2';

export const PAGE_CHANGE_LOG = 'scenes/embed__change-log/index';
export const PAGE_USER_SETTING = 'scenes/embed__user-setting/index';

/** webview */
export const PAGE_WEB_VIEW = 'pages/web-view/index';
export default {
  pages: [
    PAGE_INDEX,
    PAGE_ROADMAP,
    // TODO 发布替换公告，没流量了替换掉
    PAGE_INDEX_OLD,
    PAGE_PROFILE_EDIT,
    PAGE_FAQ_DETAIL,
    PAGE_POST_DETAIL,
    PAGE_TOPIC_DETAIL,
    PAGE_FAQ_LIST,
    PAGE_BLOG_LIST,
    PAGE_BLOG_DETAIL,
    PAGE_BLOG_TEAM,
    PAGE_PROFILE,
    PAGE_NEW_POST,
    PAGE_PRO_SEC,
    PAGE_FAQ_CATEGORY,
    PAGE_FAQ_SINGLE,
    PAGE_CHANGE_LOG,
    PAGE_USER_SETTING,
    PAGE_WEB_VIEW,
  ],
  tabBar: {
    list: [
      {
        // iconPath: './components/we-resource/index.png',
        // selectedIconPath: './components/we-resource/index_act.png',
        pagePath: PAGE_INDEX,
        // text: '首页'
      },
      {
        // iconPath: './components/we-resource/question.png',
        // selectedIconPath: './components/we-resource/question_act.png',
        pagePath: PAGE_FAQ_LIST,
        // text: '常见问题'
      },
      {
        pagePath: PAGE_ROADMAP,
        // text: '功能投票'
      },
      {
        // iconPath: './components/we-resource/blog.png',
        // selectedIconPath: './components/we-resource/blog_act.png',
        pagePath: PAGE_BLOG_LIST,
        // text: '团队博客'
      },
      {
        // iconPath: './components/we-resource/mine.png',
        // selectedIconPath: './components/we-resource/mine_act.png',
        pagePath: PAGE_PROFILE,
        // text: '我的',
      },
    ],
    backgroundColor: 'white',
    borderStyle: 'white',
  },
  window: {
    backgroundColor: '#F5F5F5',
    navigationBarBackgroundColor: '#fff',
    // navigationBarTitleText: '兔小巢',
    navigationBarTextStyle: 'black',
  },
  functionalPages: {
    independent: true,
  },
  plugins: {
    captcha: {
      version: '2.0.0', // 请选择小程序插件最新版本
      provider: 'wx1fe8d9a3cb067a75',
    },
  },
  lazyCodeLoading: 'requiredComponents',
  __usePrivacyCheck__: true,
};
