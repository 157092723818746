import React from 'react';
import tagList from './utils/tagList';
/**
 * Tag组件在H5中渲染和html标签保持一致，不做额外处理
 */
type TagType = {
  [k: string]: any
}
const Tag:TagType = () => React.createElement('div');

tagList.forEach((tagName) => {
  const TagItem = React.forwardRef<any, any>(({
    scrollX, scrollY, enableBackToTop, isText, needAuth, catchMove, ...props
  }, ref) => React.createElement(tagName, { ...props, ref }));

  TagItem.displayName = 'TagItem';
  Tag[tagName] = TagItem;
});

export default Tag;
