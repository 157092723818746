export enum ErrorCodeMap {
  // 该登陆态无法访问内部产品
  TRANSITION_OA_AUTH = 5003,
  // 该登录态无权访问公共产品
  TRANSITION_PUBLIC_AUTH = 5002,
  // 当前账号需要绑定手机号
  NEED_BIND_PHONE_NUMBER = 5005
}


export enum ErrorMessageMap {
  NOT_FIND_PRODUCTS = '找不到产品',
  NOT_LOGIN = '未登录',
  NEED_LOGIN = '请先登录'
}

export enum ErrorTypeMap {
  NOT_FIND = 4004,
}
