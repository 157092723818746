/**
 * 生成 qq 登录互联地址
 * @param [redirect_url] 登录成功后重定向地址，不填则为当前地址
 * @return {string}
 */
export function getQQConnectURL(redirect_url = location.href) {
    return `/login/qq-connect?tucao_redirect_url=${encodeURIComponent(redirect_url)}`;
}

export function getIoaUrl(redirect_url = location.href) {
    let url = redirect_url.replace(`https://${location.host}/`, '');
    // OA 登录的跳转地址第一个字符不需要右斜杠
    if (url.slice(0, 1) === '/') {
        url = url.substr(1);
    }
    const callback = `https://${location.host}/login/oaTof?tucao_redirect_uri=${url}`;
    return `https://passport.woa.com/modules/passport/signin.ashx?oauth=true&url=${encodeURIComponent(callback)}&appkey=teg_tof_auth_txc`;
}
