
/**
 * 获取URL上某个参数
 * @param {string} param 要获取的参数
 */
const getUrlParam = (param) => {
    const url = new URL(location.href);

    // AppleWebkit/601.7.8 及以下版本，该 API 的 polyfill 会失效
    if (url && url.searchParams) {
        return url.searchParams.get(param);
    }

    return null;
};

export default getUrlParam;