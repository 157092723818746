import { isIpad, isWxMiniProgram } from 'components/env';
import getUrlParams from 'components/util/get-url-params';
import getProductId from './get-product-id';

const UA_STRPOS_CACHE = {};

function userAgentStrpos(str) {
  let strposRes = UA_STRPOS_CACHE[str];

  if (typeof strposRes === 'boolean') {
    return strposRes;
  }

  strposRes = navigator.userAgent.toLocaleLowerCase().indexOf(str.toLocaleLowerCase()) > -1;
  UA_STRPOS_CACHE[str] = strposRes;
  return strposRes;
}

function getHost() {
  return `${location.protocol}//${location.host}`;
}

// 获取当前产品页url前缀
function getPrefixPath() {
  return `${getHost()}/${inMobile() ? 'embed/phone' : 'products'}/${getProductId()}`;
}

// 是productions页面
function isProductsPage() {
  if (process.env.TARO_ENV === 'weapp') {
    return false;
  }
  return location.pathname.startsWith('/product');
}

// 是embed页面
function isEmbedPage() {
  if (process.env.TARO_ENV === 'weapp') {
    return false;
  }
  return location.pathname.startsWith('/embed');
}

// 是管理后台页面
function isDashboardPage() {
  if (process.env.TARO_ENV === 'weapp') {
    return false;
  }
  return location.pathname.startsWith('/dashboard');
}

// 是CE页面
function isEnterprisePage() {
  if (process.env.TARO_ENV === 'weapp') {
    return false;
  }
  return location.pathname.startsWith('/enterprise');
}

// 是否在微信中打开
function openInWechat() {
  return userAgentStrpos('MicroMessenger') && !openInWorkWechatMobile() && !openInWorkWechatPc();
}

// 是否在PC企业微信中打开
function openInWorkWechatPc() {
  return userAgentStrpos('wxwork') && !userAgentStrpos('Mobile');
}
// 是否在腾讯视频中打开
function isTencentMeeting() {
  return userAgentStrpos('tencent_wemeet') && userAgentStrpos('Mobile');
}

// 是否在移动端企业微信打开
function openInWorkWechatMobile() {
  return userAgentStrpos('wxwork') && userAgentStrpos('Mobile');
}

// 是否在moa打开
function openInMoaMobile() {
  return userAgentStrpos('MOA') && userAgentStrpos('Mobile');
}

// 是否在km中打开
function openInKMMobile() {
  return userAgentStrpos('MobileKM') && userAgentStrpos('Mobile');
}

// 是否在手Q打开
function inMobileQQ() {
  return (
    userAgentStrpos('QQ/')
    && (userAgentStrpos('Mobile')
      || userAgentStrpos('Android')
      || userAgentStrpos('iPhone'))
  );
}

// 是否在移动端打开
function inMobile() {
  return (
    userAgentStrpos('Android')
    || userAgentStrpos('webOS')
    || userAgentStrpos('iPhone')
    || userAgentStrpos('iPod')
    || userAgentStrpos('BlackBerry')
  );
}

function initPageInfo() {
  // 小程序环境是定死的，不用再二次判断
  if (process.env.TARO_ENV === 'weapp') {
    return {
      isProducts: false,
      isEmbed: true,
      isEnterprise: false,
      isDashboard: false,
      inWechat: false,
      inWorkWechatPc: false,
      inWorkWechatMobile: false,
      inMoa: false,
      inKm: false,
      inMobileQQ: false,
      inMeeting: false,
      inMobile: false,
      inIpad: false,
      inMp: false,
      name: window.__WEBSHOP_FILE_NAME || 'other',
      prefixPath: '',
      host: '',
      productId: getProductId() || '',
      params: getUrlParams(),
    };
  }
  return {
    isProducts: isProductsPage(),
    isEmbed: isEmbedPage(),
    isEnterprise: isEnterprisePage(),
    isDashboard: isDashboardPage(),
    inWechat: openInWechat(),
    inWorkWechatPc: openInWorkWechatPc(),
    inWorkWechatMobile: openInWorkWechatMobile(),
    inMoa: openInMoaMobile(),
    inIpad: isIpad,
    inKm: openInKMMobile(),
    inMobileQQ: inMobileQQ(),
    inMobile: inMobile(),
    inMp: isWxMiniProgram(),
    inMeeting: isTencentMeeting(),
    name: window.__WEBSHOP_FILE_NAME || 'other',
    prefixPath: getPrefixPath(),
    host: getHost(),
    productId: getProductId() || '',
    params: getUrlParams(),
  };
}

export const pageInfo = initPageInfo();
